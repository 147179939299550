import React from 'react'
import { StaticImage, getImage } from 'gatsby-plugin-image'
import Layout from '../components/layout'
import Seo from '../components/seo'
import ContactSection from '../components/organisms/ContactSection'
import Banner from '../components/organisms/Banner'
import DocBox from '../components/molecules/DocBox'
import SketchBox from '../components/molecules/SketchBox'
import 'react-image-lightbox/style.css';
import {graphql} from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const DocumentationPage = ({data}) => {
  const { t } = useTranslation()
  return (
    <Layout>
      <Seo title={t('pageTitle')} />
      <section className='px-4 border-b-12 lg:border-b-36 border-secondary md:border-secondary py-8 md:py-20 pb-8 shadow-section'>
        <div className='w-full max-w-screen-xl mx-auto'>
          <h1 className='block text-primary text-lg uppercase font-bold has-decorator mb-10'>
            {t('pageTitle')}
          </h1>
          {data.allStrapiDocumentCategories.edges.map((category) => {
            return (
              <div key={category.node.id}>
                <h3 className='text-primary font-xl font-semibold'>{category.node.Name}</h3>
                <div className='mt-10 flex flex-wrap -mx-4'>
                  {category.node.technical_documentations.map((doc) => {
                    return <DocBox key={doc.id} country={doc.language} image={doc.Icon.localFile.url} file={doc.File.localFile.url} />
                  })}
                </div>
              </div>
            )
          })}
        </div>
      </section>
      <section id="technicalDrawings" className='px-4 border-b-12 lg:border-b-36 border-secondary md:border-secondary py-8 md:py-16'>
        <div className='w-full max-w-screen-xl mx-auto'>
          <h2 className='block text-primary text-lg uppercase font-bold has-decorator mb-10'>
            {t('technicalDrawingsHeader')}
          </h2>
          <div className='mt-10 xl:mt-16 flex flex-wrap -mx-4 xl:-mx-8'>
            {data.allStrapiTechnicalDrawings.edges.map(({node: item}) => {
              return (
                <SketchBox key={item.id} name={item.name} image={getImage(item.thumb.localFile.childImageSharp)} url={item.file.localFile.url} />
              )
            })}
          </div>
        </div>
      </section>

      <Banner
        image={
          <StaticImage
            src="../images/banner.jpg"
            layout='fullWidth'
            transformOptions='fit'
            quality={100}
            alt={t('bannerSection.header')}
            style={{
              height: '100%',
            }}
        />
        }
        text={t('bannerSection.header')}
    />
      <ContactSection />
  </Layout>
  )
}

export default DocumentationPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "documentationPage"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allStrapiDocumentCategories(
      sort: {fields: strapiId, order: DESC}
      filter: {locale: {eq: $language}}
    ) {
      edges {
        node {
          technical_documentations {
            id
            name
            language
            document_category
            Icon {
              localFile {
                url
              }
            }
            File {
              localFile {
                url
              }
            }
          }
          id
          Name
          strapiId
        }
      }
    }
    allStrapiTechnicalDrawings(filter: {locale: {eq: $language}}) {
      edges {
        node {
          id
          name
          thumb {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          file {
            localFile {
              url
            }
          }
        }
      }
    }
  }
`;
