import React from 'react'

const DocBox = ({country, image, file}) => {
  return (
    <div className='px-4 mb-8 w-1/2 sm:w-1/3 lg:w-1/4 xl:w-1/5'>
      <a href={file} target='_blank' rel='noreferrer' className='block bg-gray-10 shadow-md group'>
        <div className='px-2 py-3 bg-gray-10 flex justify-center items-center'>
          <div className='mr-2 flex-shrink-0 flex'>
            <img src={image} alt={country} width='32px' height='32px' className='m-0' />
          </div>
          <div className='lg:text-lg font-bold'>
            {country}
          </div>
        </div>
      </a>
    </div>
  )
}

export default DocBox
