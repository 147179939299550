import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

const Product = ({name, image, url}) => {
  return (
    <div className='px-4 xl:px-8 mb-8 xl:mb-16 w-full sm:w-1/2 lg:w-1/3 xl:w-1/4'>
      <a href={url} target='_blank' rel='noreferrer' className='block bg-gray-10 h-full group'>
        <div className='aspect-w-15 aspect-h-16 bg-primary-light'>
          <div className='transition-opacity duration-300 ease-in-out bg-white z-10 opacity-0 group-hover:opacity-50'></div>
          <div>
            <GatsbyImage
              image={image}
              alt={name}
              style={{height: '100%'}}
            />
          </div>
        </div>
        <div className='px-4 py-4 bg-gray-10'>
          <h3 className='text-primary font-bold lg:text-lg'>{name}</h3>
        </div>
      </a >
    </div>
  )
}

export default Product
